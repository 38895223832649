.vl {
    border-left: 1px solid #ccc;
    height: 100%;
}

#outlined-basic {
    padding-bottom: 36px;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    max-width: unset;
}