#footer {
  max-height: 3.5vh;
  height: 3.5vh;
  margin: 0;
  padding: 0;
  position: sticky;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  background-color: var(--background-color, white);
}

.copyright {
  color: var(--text-color) !important;
}
