.header-nav-dropdown {
  max-width: unset;
  background-color: var(--background-color);
}

.dark-theme-toggler {
  font-size: 1.6rem;
  padding: 0;
  margin-right: 2rem;
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dark-theme-toggler button {
  border: none;
  color: var(--text-color);
  padding-bottom: 0.4rem;
  border-radius: 1rem;
  background-color: var(--background-color);
}

.dark-theme-toggler button:hover {
  background-color: var(--quaternary-color);
}

.theme-toggle-btn {
  width: 2.2rem;
  height: 2.2rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#header {
  background-color: var(--background-color, white);
  z-index: 5;
}
#header * {
  color: var(--text-color);
  background-color: var(--background-color, white);
}

.main-logo {
  width: 11rem;
  min-width: 5rem;
  min-height: 3.3rem;
  margin: 0.5rem 0 0 0;
  padding: 0;
  transition: all 0.3s ease-in-out;
}

#header .dropdown-menu {
  min-width: 15rem !important;
  max-width: 17rem !important;
  max-height: 65vh !important;
  overflow-y: auto !important;
}
#header .dropdown-menu .message-item a > div {
  width: 100%;
  max-width: 15rem !important;
}

.header_profile_img {
  min-width: 2.5rem;
  min-height: 2.5rem;
}
