.property-panel-content .e-checkbox-wrapper.personal .e-label {
  color: #808080;
}

.property-panel-content .e-checkbox-wrapper.personal .e-frame {
  background-color: #c43081;
  border-color: transparent;
}

.property-panel-content .e-checkbox-wrapper.company .e-frame {
  background-color: #ff7f50;
  border-color: transparent;
}

.property-panel-content .e-checkbox-wrapper.birthday .e-frame {
  background-color: #af27cd;
  border-color: transparent;
}

.property-panel-content .e-checkbox-wrapper.holiday .e-frame {
  background-color: #808000;
  border-color: transparent;
}

.highcontrast
  .property-panel-content
  .e-checkbox-wrapper.e-resource
  .e-frame.e-check,
.bootstrap
  .property-panel-content
  .e-checkbox-wrapper.e-resource
  .e-frame.e-check,
.material3-dark
  .property-panel-content
  .e-checkbox-wrapper.e-resource
  .e-frame.e-check {
  color: #fff;
}

.dynamic-resource.e-schedule .e-timeline-view .e-resource-left-td,
.dynamic-resource.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 110px;
}

.dynamic-resource.e-schedule .e-timeline-view .e-resource-text,
.dynamic-resource.e-schedule .e-timeline-month-view .e-resource-text {
  padding: 0;
}

.e-quick-popup-wrapper .e-event-popup .e-sorting-1,
.e-quick-popup-wrapper .e-event-popup .e-circle-check,
.e-resource .e-circle-check {
  color: rgba(0, 0, 0, 0.54);
  font-size: 17px;
  padding: 0 13px 0 0;
}

.e-quick-popup-wrapper .e-event-popup .e-user {
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
  font-size: 17px;
  padding: 0 10px 0 0;
}
