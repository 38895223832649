/* ============ Start Survey JS CSS ============== */

body {
  /* SurveyJS Creator V2 */
  --primary: #0e548d;
  --primary-light: rgba(26, 183, 250, 0.1);
  /* --foreground: #3979ad; */
  /* --primary-foreground: #ffffff; */
  --secondary: #2ba9df;
  /* --background: #f6f6f6; */
  /* --background-dim: #efefefef; */
  /* --background-dim-light: #f6f6f6;  */
}

/* ============ End Survey JS CSS ============== */

.reviewed {
  background-color: #b8d7f5;
  border-color: #c3e6cb;
}

.not-reviewed {
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.completed-row {
  background-color: #d4eddad3 !important;
  border-color: #000000;
  color: #000000;
}

.pending-row {
  background-color: #fdfdd7 !important;
  border-color: #000000;
  color: #000000;
}

.pending-row:hover {
  background-color: #dad5d5;
  color: #000000;
}

.pending-row[aria-selected="true"],
.completed-row[aria-selected="true"],
.MuiDataGrid-row.Mui-selected {
  background-color: rgba(137, 193, 240, 0.904) !important;
  filter: drop-shadow(1px 1px 5px rgb(164, 169, 182));
}

.sd-input--disabled,
.sd-item--disabled span,
.sd-checkbox__label--disabled {
  color: #484848 !important;
  opacity: 0.7 !important;
}

.sd-boolean__thumb {
  background-color: #b5b5b5 !important;
  opacity: 0.7 !important;
}

.sd-item--disabled .sd-item__svg,
.sd-radio--disabled.sd-radio--checked .sd-radio__decorator {
  opacity: 1 !important;
  background-color: #0855948e !important;
}

.forms-charts {
  min-width: fit-content;
  min-height: fit-content;
  max-width: 100%;
  max-height: 100%;
  border: #c5c5c5 0.5px solid;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.custom-icon {
  margin-left: 10px;
}

/* cstm survey new design styles */

.cstm_Survey_table {
  margin: 1.5rem 0 !important;
  transition: 0.5s all ease;
}

.cstm_Survey_table .card {
  padding: 0 !important;
  padding-left: 1rem !important;
  border: none !important;
  border-radius: 10px;
}

.cstm_Survey_table table {
  width: 100%;
  background-color: #fff !important;
  margin-bottom: 0 !important;
  /* border: 1px solid #e0e0e0; */
  border-radius: 0 10px 10px 0;
}

.cstm_Survey_table tr {
  border-radius: 0 10px 10px 0 !important;
}

.cstm_Survey_table th {
  background-color: #f8f9fa;
  color: #000;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 0 10px 0 0 !important;
}

.cstm_Survey_table td {
  border-right: 2px solid #c7c7c7;
  padding: 0 !important;
}

.cstm_Survey_table td:last-child {
  border-right: none;
  border-radius: 0 10px 10px 0 !important;
}

.cstm_Survey_table td:last-child button {
  border-radius: 0 0 10px 0 !important;
}

.cstm_Survey_table td button {
  font-size: 0.9rem;
  background-color: #d6dce2;
  padding: 4px 0 !important;
  transition: 0.3s all ease;
}
.cstm_Survey_table td button:hover {
  background-color: #2a78b7f2;
  color: #fff;
  font-weight: 500;
}

.cstm_Survey_table td button.bg-danger:hover {
  background-color: #fff !important;
  color: red !important;
  font-weight: 500;
}

.cstm_Survey_table td button.bg-danger:disabled {
  background-color: #d6dce2 !important;
  color: #8f8f8f !important;
}
/* Start Media Queries  */

@media only screen and (max-width: 480px) {
  .apexcharts-toolbar {
    top: 24px !important;
  }
}
