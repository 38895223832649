@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
.e-appointment-wrapper * {
  max-width: unset;
}

.header-customization {
  padding-bottom: 10px;
}

.property-customization {
  padding-right: 5px;
}

.button-customization {
  padding: 15px;
}

@media (max-width: 441px) {
  .button-customization {
    padding: 30px;
  }
}

.schedulerGrid .MuiDataGrid-toolbarContainer {
  background: unset;
  border-radius: 0 !important;
  color: #000 !important;
}

.schedulerGrid .MuiDataGrid-toolbarContainer button {
  color: #000 !important;
}

.schedulerGrid .MuiDataGrid-root,
.schedulerGrid .MuiDataGrid-columnHeaders {
  border-radius: 0px !important;
  border: 1px solid rgba(224, 224, 224, 1) !important;
  box-shadow: unset;
  color: #000 !important;
  background: unset;
}
