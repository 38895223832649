.dash-card {
  transition: 0.3s ease-in-out;
  box-sizing: border-box;
}

.dash-card:nth-of-type(1):hover,
.dash-card:nth-of-type(2):hover {
  cursor: pointer;
  scale: 1.01;
  filter: drop-shadow(0 0 0.5rem rgba(172, 206, 234, 0.521));
}

.dash-card:nth-of-type(1):hover .card,
.dash-card:nth-of-type(2):hover .card {
  border-color: #d6e9f2;
}

.announcement_container {
  min-height: 40.05vh !important;
  max-height: 40.05vh !important;
  overflow-y: auto;
}

.dash_messages {
  min-height: 40.05vh !important;
  max-height: 40.05vh !important;
  overflow-y: auto;
}

#dashboard_welcome_note {
  position: absolute;
  top: 3.8rem;
  right: 1px;
}

.dashboard_container h5 {
  color: steelblue;
  font-size: 1.2rem !important;
}

.recent_msg_div {
  box-shadow: 3px 3px 5px rgba(105, 136, 165, 0.342);
  border-radius: 1.2rem;
}

@media (max-width: 900px) {
  .dashboard h1 {
    width: unset;
  }
}
@media (max-width: 760px) {
  .announcement_container {
    min-height: unset;
    max-height: 50vh !important;
    overflow-y: auto;
  }

  .dash_messages {
    min-height: unset !important;
    max-height: unset !important;
    overflow-y: auto;
  }
}
