/* general */
#phone_icon_pt {
  transform: rotate(110deg);
}

.pt_header_notifs span {
  position: relative !important;
  top: -0.4rem !important;
  left: 2.1rem !important;
}

.header_small_pt {
  display: none !important;
}

.pt_content h1 {
  color: #25649e;
}

#main h1 {
  color: #25649e;
}

.large-header {
  height: 5.3rem;
}

.header_small_pt .logo img {
  width: 7rem !important;
  min-height: 2.5rem !important;
  margin: 0;
  padding: 0;
}

.pt_profile_img {
  max-width: 2.5rem !important;
}

.header_small_pt .pt_profile_img {
  max-width: 2.7rem !important;
  margin-left: 1rem;
  position: relative;
  top: -0.5rem;
}

.pt_headers .dropdown-menu {
  min-width: 12rem;
  min-height: 11.5rem;
  position: relative;
  top: 0.7rem !important;
}

.pt_headers .dropdown-menu li {
  background-color: #fff;
  color: #525252;
}

.pt_headers li .dropdown-menu .active {
  background-color: rgba(7, 87, 153, 0.733) !important;
  color: #ffffff;
  border-radius: 0.2em;
}

.pt_headers .dropdown-toggle::after {
  transform: rotate(90deg);
  position: relative;
  top: 0.17rem;
}
.pt_headers .dropdown-toggle.active::after {
  transform: rotate(-90deg);
}

/* media query for max size  900px*/
@media only screen and (max-width: 900px) {
  .large-header {
    display: none !important;
  }

  .header_small_pt {
    display: flex !important;

    background-color: #fff !important;
  }

  #responsive-navbar-nav {
    background-color: #fff;
    min-width: 100% !important;
    padding: 2rem 1rem 1rem 1rem;
    margin: 0 !important;
    margin-top: 1rem;
    z-index: 100 !important;
  }

  .header_small_pt .small-header {
    display: flex !important;
    min-width: 100% !important;
    max-width: 100% !important;
    height: 6.5rem;
    z-index: 100 !important;
  }

  #content.pt_content {
    margin-top: unset !important;
    min-height: 92vh;
  }
  .pt_portal .chat-app {
    min-height: 80vh !important;
  }

  .pt_content .chat-app {
    max-height: 85vh;
    min-height: 85vh;
    margin: 1rem 0;
  }
}

@media (min-width: 901px) {
  #content.pt_content {
    /* margin-top: unset !important; */
    min-height: 85vh;
  }
  .pt_portal .chat-app {
    margin: 0.1rem 2rem;
  }
  .pt-profile-container {
    min-height: 90vh;
  }
  #pt_survey_content {
    margin-top: unset !important;
  }

  .pt_content {
    margin: 1rem !important;
  }
}

/* media query for max size  385px*/
@media (max-width: 385px) {
  #wrapper .pt_portal {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    background-image: url("../../assets/images/main-bg.jpg");
    background-size: cover;
    z-index: 0;
  }

  .navbar-toggler {
    padding: 0.2rem 0.2rem !important;
    font-size: 1.5rem !important;
  }

  .pt_portal .header_small_pt {
    min-width: 100%;
    position: fixed;
    top: 0;
    z-index: 100 !important;
    max-height: 6rem !important;
  }

  .pt_portal .header_small_pt .logo_img_pt {
    margin: 0;
    padding: 0;
    width: 7rem;
    height: auto;
  }

  main.pt_main {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0;
    margin-bottom: 2rem;
    row-gap: 1rem;
  }

  main.pt_main div.pt_card {
    width: 42vw;
    height: auto;
    border: 2px solid rgb(13, 102, 175);
    padding: 0.5rem;
    text-align: center;
    border-radius: 0.9em;
    color: steelblue;
    font-size: 0.85rem;
    font-weight: 600;
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 10px 2px #97d9ff;
  }

  main.pt_main div.pt_card:hover {
    cursor: pointer;
  }

  main.pt_main div.pt_card img {
    max-width: 38vw;
    border-radius: 1em 1em 0 0;
    margin-bottom: 0.5rem;
  }

  main.pt_main div.pt_card p {
    margin: 0;
    padding: 0;
    color: rgb(28, 116, 189);
    font-size: 0.8rem;
    font-weight: 700;
    text-shadow: 0 0 2px #aefffb;
  }

  main.pt_main div.pt_main_intro {
    box-sizing: content-box;
    min-width: 100.2vw;
    min-height: 28vh;
    height: auto;
    padding: 0 0.5rem 0.5rem 0.5rem;
    margin: 0 0 0.3rem 0;
    border-top: 20px solid #1b50a0;
    position: relative;
    top: -0.6rem;
    background: url("../../assets/images/pt_intro.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .samy {
    color: #07579993;
  }

  #pt_portal .pt_main_intro h1 {
    font-size: 1.5rem !important;
    padding: 0 2rem 0 0.3rem !important;
    margin: 0.8rem 0 0 0 !important;
    width: 70%;
    color: #0c61a7;
    text-shadow: -1.2px -1.2px 2px #00598343;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-weight: 700;
    padding: 1rem;
    border-radius: 0.5em;
  }

  #pt_portal .pt_main_intro h1 .pt_h1_curo {
    color: rgb(34, 218, 255);
    /* font-weight: 400 !important; */
    font-family: "Cutive Mono", monospace;
    font-size: 1.8rem;
  }

  #pt_portal .pt_main_intro img {
    max-width: 100%;
    position: absolute;
    margin: 0 !important;
  }

  .pt_card img.contact_pt_img {
    transform: scaleX(-1);
  }

  main.pt_main div.pt_card_profile img {
    filter: drop-shadow(4px -2px 2px #495c74c4);
  }
}

/* media query for min size  385px */
@media (min-width: 385px) {
  #wrapper .pt_portal {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    background-image: url("../../assets/images/main-bg.jpg");
    background-size: cover;
    z-index: 0;
  }

  .header_small_pt .sm_header_container {
    min-width: 98% !important;
    min-height: 100%;
  }

  .navbar-toggler {
    padding: 0.2rem 0.2rem !important;
    font-size: 1.5rem !important;
  }

  .pt_portal .header_small_pt {
    min-width: 100%;
    position: fixed;
    top: 0;
    z-index: 100 !important;
  }

  .pt_portal .header_small_pt .logo_img_pt {
    margin: 0;
    padding: 0;
    width: 10rem;
    height: auto;
  }

  main.pt_main {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0;
    margin-bottom: 2rem;
    row-gap: 1rem;
  }

  main.pt_main div.pt_card {
    width: 40vw;
    min-height: 10rem;
    height: auto;
    border: 2px solid rgb(13, 102, 175);
    padding: 0.5rem;
    text-align: center;
    border-radius: 0.9em;
    color: steelblue;
    font-size: 0.85rem;
    font-weight: 600;
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 10px 2px #97d9ff;
  }

  main.pt_main div.pt_card:hover {
    cursor: pointer;
  }

  main.pt_main div.pt_card img {
    max-width: 36vw;
    border-radius: 1em 1em 0 0;
    margin-bottom: 0.5rem;
  }

  main.pt_main div.pt_card_profile img {
    filter: drop-shadow(4px -2px 2px #495c74c4);
  }

  main.pt_main div.pt_card p {
    margin: 0;
    padding: 0;
    color: rgb(28, 116, 189);
    font-size: 1rem;
    font-weight: 700;
    text-shadow: 0 0 2px #aefffb;
  }

  main.pt_main div.pt_main_intro {
    box-sizing: content-box;
    min-width: 100.2vw;
    min-height: 24vh;
    height: auto;
    padding: 0 0.5rem 0.5rem 0.5rem;
    margin: 0 0 0.3rem 0;
    border-top: 20px solid #1b50a0;
    position: relative;
    top: -0.6rem;
    background: url("../../assets/images/pt_intro.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  #pt_portal .pt_main_intro h1 {
    font-size: 1.7rem !important;
    padding: 0 2rem 0 0.3rem !important;
    margin: 0.8rem 0 0 0 !important;
    width: 70%;
    color: #0c61a7;
    text-shadow: -1.2px -1.2px 2px #00598343;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-weight: 700;
    padding: 1rem;
    border-radius: 0.5em;
  }

  #pt_portal .pt_main_intro h1 .pt_h1_curo {
    color: rgb(34, 218, 255);
    font-weight: 400 !important;
    font-family: "Cutive Mono", monospace;
    font-size: 1.8rem;
  }

  #pt_portal .pt_main_intro img {
    max-width: 100%;
    position: absolute;
    margin: 0 !important;
  }

  .pt_card img.contact_pt_img {
    transform: scaleX(-1);
  }
}

/* media query for min size  430px */
@media (min-width: 430px) {
  main.pt_main div.pt_main_intro {
    box-sizing: unset;
    background: unset;
    border: unset;
    min-height: unset;
    max-width: 100%;
    background: url("../../assets/images/pt_intro.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 15rem;
    max-height: 20rem;
    background-position: bottom;
    border-top: 20px solid #1b50a0;
  }
}

/* media query for min size  550px */
@media (min-width: 550px) {
  main.pt_main div.pt_main_intro {
    margin-top: -1rem;
  }

  main.pt_main div.pt_card {
    width: 10rem;
  }

  main.pt_main div.pt_card img {
    max-width: unset;
    width: 100%;
  }

  #pt_portal .pt_main_intro h1 {
    font-size: 2rem !important;
    padding: unset !important;
    margin: 0.8rem 0 0 0 !important;
    width: 50%;
  }
}
/* media query for min size  900px */
@media (min-width: 900px) {
  main.pt_main div.pt_main_intro {
    box-sizing: border-box !important;
    margin-top: unset;
    min-width: 85vw;
    min-height: 25rem;
    margin: 0 5rem;
    background: url("../../assets/images/pt_intro-md.jpg");
    background-size: cover;
    background-position: right;
    background-color: white;
    background-repeat: no-repeat;
  }

  #pt_portal .pt_main_intro h1 {
    font-size: 2.5rem !important;
    padding: 1rem 1rem !important;
    margin: 0.8rem 0 0 0 !important;
    width: 35%;
  }
  #pt_portal .pt_main_intro h1 .pt_h1_curo {
    font-size: 2.5rem !important;
  }
  main.pt_main div.pt_card {
    transition: 0.3s ease-in-out;
  }
  main.pt_main div.pt_card:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px 5px #ffffff;
  }

  .pt_header_active {
    color: rgb(18, 89, 147);
    text-shadow: 1px 1px 4px #c7d4de;
    font-size: large;
  }

  .pt_header_active:hover {
    color: rgb(18, 89, 147);
    text-shadow: 2px 2px 2px #c7d4de;
  }
}
/* media query for min size  1200px */
@media (min-width: 1200px) {
  main.pt_main div.pt_main_intro {
    background: url("../../assets/images/pt_intro-lg.jpg");
    background-position: right bottom;
    background-size: cover;
  }
}
