.errorrow {
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.errorcell{
    background-color: #86000d;
    border-color: #86000d;
}

.headertext{
        text-align: "center";
        /* border: "2px dotted red"; */
        border-style:dotted ;
        padding: "5px";
      }
