#sidebar {
  z-index: 2;
  background: var(
    --background-color,
    linear-gradient(
      -45deg,
      var(--tertiary-color),
      var(--secondary-color),
      var(--primary-color)
    )
  ) !important;
  width: 15rem;
}

#sidebar * {
  background-color: var(--background-color, transparent);
}

@media (max-width: 800px) {
  #sidebar {
    background: var(
      --background-color,
      linear-gradient(
        -45deg,
        rgba(100, 164, 216, 0.95),
        rgb(70, 130, 180, 0.95),
        rgb(27, 78, 121, 0.95)
      )
    ) !important;
  }
}
