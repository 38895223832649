/* 1 rem = 16px */

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,500;0,700;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* ----------- General Styles to enhance responsiveness ---------*/

* {
  box-sizing: border-box !important;
  max-width: 100%;
  color: var(--text-color);
}

body {
  font-family: "Lato", sans-serif;
  line-height: 1.5;
  color: var(--text-color);
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

/* ----------- General Styles to enhance responsiveness ---- END */

/* ----------- CSS variables ----------------------------------- */
:root {
  --primary-color: #1b4e79;
  --secondary-color: #4682b4;
  --tertiary-color: #64a4d8;
  --quaternary-color: #bdddf7;
  --color-white: #fff;
  --color-black: #000;
  --text-color: ;
  --background-color: ;
}

/* ----------- CSS variables END ------------------------------- */

.curo_btn_primary {
  background: linear-gradient(
    180deg,
    var(--tertiary-color),
    var(--secondary-color),
    var(--primary-color)
  );
  border: 2px solid #4682b4;
  color: var(--color-white);
  font-weight: 500;
  padding: 0.6rem 0.8rem;
  text-shadow: 1px 1px 2px #3e3e3e;
  transition: all 0.2s ease-in;
  border-radius: 0.7rem;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
}

.curo_btn_primary:hover {
  background: linear-gradient(184deg, #b5deff, #fff, #b5deff);
  color: var(--primary-color);
  -webkit-filter: drop-shadow(2px 2px 4px #25649e);
  filter: drop-shadow(2px 2px 4px #25649e);
  font-weight: 700;
  text-shadow: 1px 1px 2px #ededed;
}

#main {
  min-height: 91vh;
  background-color: var(--background-color, #ffffff) !important;
}

.bg-toggler {
  background: var(--background-color, inherit) !important;
}

.txt-toggler {
  color: var(--text-color, inherit) !important;
}

/* ----------- from V1 --------------------------------------- */

* {
  font-family: "Nunito Sans", sans-serif;
}

.btn-success {
  padding: 0.3rem 0.5rem !important;
  font-weight: 600 !important;
  color: white !important;
  border: 2px solid steelblue !important;
  background: linear-gradient(180deg, #64a4d8, steelblue, #1b4e79);
  text-shadow: 1px 1px 2px #3e3e3e;
  transition: all 0.1s ease-in !important;
}

.btn-success:hover {
  background: linear-gradient(184deg, #b5deff, #d8edff, #b5deff) !important;
  color: #1b4e79 !important;
  text-shadow: 1px 1px 2px #ededed !important;
  filter: drop-shadow(2px 2px 4px #25649e) !important;
  font-weight: 700 !important;
}

.ag_grid_cell {
  color: #000000;
}

.ag-header {
  background: linear-gradient(to bottom, #6aaee6, #1b4e79 100%, #66ace5);
}

.ag-header-cell {
  color: rgb(233, 244, 255) !important;
  font-weight: 600 !important;
  font-size: 0.85rem;
}

.ag-root-wrapper {
  border-radius: 1em !important;
  border: 0.12em solid #2972ae !important;
  box-shadow: -1px 1px 5px #5c5c5c;
  height: 59vh;
}

#ag-132 {
  border: 0.12em solid #2972ae !important;
  border-top: none !important;
  box-shadow: 0px 1px 5px #5c5c5c;
  border-radius: 0 0 1em 1em !important;
  padding-bottom: 0.32rem;
}

.ag-theme-alpine button {
  border-radius: 0.5em;
  margin: 0 0.1rem 0.05rem 0;
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  border-color: steelblue;
  background: linear-gradient(180deg, #64a4d8, steelblue, #1b4e79);
  text-shadow: 1px 1px 2px #3e3e3e;
  transition: all 0.2s ease-in;
}

.ag-theme-alpine button:hover {
  background: linear-gradient(184deg, #b5deff, #ffffff, #b5deff);
  color: #1b4e79 !important;
  text-shadow: 1px 1px 2px #ededed !important;
  filter: drop-shadow(2px 2px 4px #25649e);
  font-weight: 600 !important;
}

#mng_pt_grid .ag-root-wrapper {
  border-radius: 1em 1em 0 0 !important;
}

.dash-card {
  max-height: 20rem;
}

.dash-card img {
  height: 9.5rem;
  width: auto;
  padding: 0.2rem 0.5rem;
}

.dash-card h5 {
  font-size: 0.9rem !important;
}

.dash-card p {
  font-size: 0.85rem !important;
}

.dash-card .card-body {
  padding: 0.5rem 0;
  margin: 0;
}

.survey-option-btn {
  text-decoration: none;
  color: #5cb85c;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(129, 167, 202);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}

.clickable_text {
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease-out;
}

.clickable_text:hover {
  transform: scale(1.02);
  color: #163dff;
}
/* ----------- from V1 END ----------------------------------- */

/* ============== Material UI styles Start   */

.MuiDataGrid-virtualScroller .MuiDataGrid-overlayWrapperInner {
  max-width: unset !important;
}

.pointer-cursor {
  cursor: pointer;
}

.MuiDataGrid-panelContent .MuiInputBase-root select,
.MuiDataGrid-panelContent .MuiInputBase-root input {
  min-height: 2rem;
}

.pt_active {
  background: linear-gradient(180deg, #8cf193, #059e38 90%, #8cf193);
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 4px #3f3f3f;
  border-radius: 0.5em;
  font-weight: 600;
}

.pt_inactive {
  background: linear-gradient(180deg, #dcdcdc, rgb(109, 109, 109) 90%, #1d1d1d);
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 4px #3f3f3f;
  border-radius: 0.5em;
  font-weight: 600;
}

.ag-header {
  background: linear-gradient(to bottom, #6aaee6, #1b4e79 100%, #66ace5);
}

.ag-header-cell {
  color: rgb(233, 244, 255) !important;
  font-weight: 600 !important;
  font-size: 0.85rem;
}

.ag-root-wrapper {
  border-radius: 1em !important;
  border: 0.12em solid #2972ae !important;
  box-shadow: -1px 1px 5px #5c5c5c;
  height: 59vh;
}

.MuiDataGrid-root {
  border-radius: 1em !important;
  border: 0.12em solid #2972ae !important;
  box-shadow: -1px 1px 5px #5c5c5c;
}

#mng_pt_grid .MuiDataGrid-footerContainer {
  display: none;
}
#mng_pt_grid .MuiDataGrid-root {
  border-radius: 1em 1em 0 0 !important;
}

.MuiDataGrid-toolbarContainer {
  background: linear-gradient(to bottom, #6aaee6, #1b4e79 100%, #66ace5);
  border-radius: 1em 1em 0 0 !important;
}

.MuiDataGrid-toolbarContainer button {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  color: white;
}

.MuiDataGrid-columnHeaders {
  background: linear-gradient(to top, #ffffff, #def0ff74, #caedff 100%);
  border-radius: 0 0 1em 1em !important;
  border-bottom: 0.12em dotted #4598dd !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:nth-of-type(2n) {
  background-color: #e9f1f74e;
}

/* ============== Material UI styles End   */

/* ============== Manage Patient Start =====================   */
.mng-patient-pagination {
  max-height: 1.6rem;
  padding-bottom: 0.4rem !important;
}

.cellwa div {
  margin-left: auto;
  margin-right: auto;
}

/* ============== Manage Patient End  =====================  */

.nav-item {
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
  color: #5e7078;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* ============== Pagination Start  =====================  */
.cstm_breadcrumb {
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.cstm_breadcrumb:hover {
  color: #1b4e79;
}
/* ============== Pagination   End  =====================  */

/* ============== PDF Viewer Start  =====================  */

.rpv-core__inner-pages {
  max-height: 80vh !important;
  overflow-y: auto;
}

/* ============== PDF Viewer END  =====================  */

/* ============== Caregivers Manage START  =====================  */
.provider_list_item {
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  transition: all 0.1s ease-out;
  margin-right: auto;
  margin-left: auto;
  box-shadow: 0px 0px 5px 0px rgba(184, 210, 231, 0.758);
}

.provider_info_subitem {
  color: #404f55;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.provider_info_subitem:hover {
  color: #1b4e79;
  transform: scale(1.05);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  max-width: 90vw;
  height: auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  z-index: 2; /* Make sure this is above other content */
}

/* Style for the modal close button */
.modal-content button {
  padding: 1px 8px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.modal-content button:hover {
  background-color: #f48282;
  transform: scale(0.95);
}
/* ============== Caregivers Manage END  =====================  */

/* ============== Custom Datepicker START  =====================  */

#custom_datepicker_container .MuiFormControl-root {
  width: 10rem;
  margin-right: 0.1rem;
  margin-bottom: 0.5rem;
  height: 4rem;
}

#custom_datepicker_container .MuiInputBase-root,
#custom_datepicker_container .MuiInputBase-root #date-range {
  max-height: 2.7rem;
  padding: 0.5rem;
}

#custom_datepicker_container .MuiInputBase-root .MuiButtonBase-root {
  max-width: fit-content;
}

/* ============== Custom Datepicker END  =====================  */

/* ============== MUI Data Grid START  =====================  */
/* pharmacy portal */

.dg_0liner {
  height: 70vh;
  width: 100%;
}

@media screen and (max-height: 900px) {
  .dg_0liner {
    height: 65vh;
  }
}

.dg_1liner {
  height: 65vh;
  width: 100%;
}

@media screen and (max-height: 900px) {
  .dg_1liner {
    height: 60vh;
  }
}

.dg_2liner {
  height: 60vh;
  width: 100%;
}

.dg_3liner {
  height: 55vh;
  width: 100%;
}

@media screen and (max-height: 900px) {
  .dg_2liner {
    height: 50vh;
  }

  .dg_3liner {
    height: 50vh;
    width: 100%;
  }
}
/* ============== MUI Data Grid END  =====================  */

/* ============== Survey Dissatisfaction START  =====================  */
.notReviewed {
  background-color: #fff8bd !important;
}

.tbc {
  color: #b3000f !important;
  font-weight: 500 !important;
}
/* ============== Survey Dissatisfaction END   =====================  */
