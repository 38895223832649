.homepage_container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 85.5vh;
  background-color: #f6f6f6;
}

.container_cir {
  position: relative;
  width: 500px;
  height: 500px;
  border: 2px solid #222;
  border-radius: 50%;
}

.container_cir .icon_cir {
  position: relative;
  left: -50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.container_cir .icon_cir .imgBx_cir {
  position: absolute;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  border: 1px solid #fff;
  box-shadow: 0 0 5px 5px #9eceee;
  transform: rotate(calc(360deg / 8 * var(--i)));
  transform-origin: 300px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #005fa4;
  filter: grayscale(0.7);
  transition: 0.5s;
  cursor: pointer;
  color: #fff;
}

.container_cir .icon_cir .imgBx_cir.active {
  box-shadow: 0 0 5px 4px #fafafa, 0 0 10px 10px rgb(30, 122, 207);
  cursor: default;
  filter: grayscale(0);
}

.container_cir .icon_cir .imgBx_cir:hover {
  box-shadow: 0 0 5px 4px #fafafa, 0 0 10px 10px rgb(30, 122, 207);
  cursor: pointer;
  filter: grayscale(0);
}

.container_cir .icon_cir .imgBx_cir h3 {
  transform: rotate(calc(-360deg / 8 * var(--i)));
  transition: 0.5s;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  z-index: 5;
}

.container_cir .icon_cir .imgBx_cir.active h3 {
  filter: grayscale(0);
  cursor: default;
}

.content_cir {
  position: absolute;
  inset: 12%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76%;
  height: 76%;
  background-color: #005fa4;
  border-radius: 50%;
}

.contentBx_cir {
  position: absolute;
  transform: scale(0);
  transition: 0.5s;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.contentBx_cir.active {
  transform: scale(1);
  opacity: 1;
  transition-delay: 0.5s;
}

.contentBx_cir .card_cir {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.contentBx_cir .card_cir .imgBx_content {
  position: relative;
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  overflow: hidden;
}
.contentBx_cir .card_cir .imgBx_content img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentBx_cir .card_cir .textBx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentBx_cir .card_cir .textBx h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.contentBx_cir .card_cir .textBx h2 span {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.contentBx_cir .card_cir .textBx .sci {
  position: relative;
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.contentBx_cir .card_cir .textBx .sci li {
  list-style: none;
}

.pt_info_box {
  background-color: #fff;
  border-radius: 0.5em;
  padding: 1rem 0.3rem;
  box-shadow: 0 0 5px 5px #6699cd52;
}

.pt_info_box_img {
  width: 100%;
  max-width: 10rem;
  box-shadow: 0 0 5px 5px rgba(209, 209, 209, 0.329);
}

.pt_main_container {
  margin-top: 2.2rem;
}

.pt_recent_msg_div,
.pt_recent_forms_div,
.pt_recent_docs_div {
  box-shadow: 0 0 2px 2px #a2b6ca25;
  border-radius: 0.5em;
  border: 2px solid #5986b325;
}

.recent_msg_sender {
  font-size: 1rem;
  font-weight: 600;
  color: #25649e;
}

.pt_msg_timestamp {
  font-size: 0.75rem !important;
  font-weight: 400;
  color: #7f7f8a;
  vertical-align: baseline;
  margin-right: 0.1rem;
}

#nav_icon_1 {
  color: rgb(218, 176, 241);
}

#nav_icon_2 {
  color: #8dd08d;
}

#nav_icon_3 {
  color: #d18aa2;
}

#nav_icon_4 {
  color: #95e2d4;
}

#nav_icon_5 {
  color: #cb9d6b;
}

#nav_icon_6 {
  color: #6bbc65;
}

#nav_icon_7 {
  color: #d2eb62;
}

.pt_nav_item .nav-link {
  font-size: 1.05rem !important;
}

.pt_homePage .card-title {
  font-size: 1.3rem !important;
  font-weight: 600;
}

.pt_nav_item a {
  padding: 0 !important;
  margin: 1.4rem 0 1.4rem 0.5rem !important;
}

.pt_recent_msg_div:hover,
.pt_recent_forms_div:hover,
.pt_recent_docs_div:hover {
  box-shadow: 0 0 5px 5px #7088a052;
  cursor: pointer;
}

.pt_card_title {
  background: #add1f0;
}

.pt_caregiver_image {
  width: 6rem;
  height: 6rem;
  box-shadow: 0 0 5px 5px rgba(209, 209, 209, 0.329);
}

.care_giver_info {
  font-size: 0.8rem;
  font-weight: 300;
}

.caregiver_container {
  box-shadow: 0 0 2px 2px #a2b6ca25;
  border-radius: 0.5em;
  border: 2px solid #5986b325;
  background: #f5ffff;
}

@media (min-width: 1024px) {
  .pt_homepage #main,
  .pt_homepage #footer {
    margin-left: 15rem !important;
  }

  .pt_homepage.toggle-sidebar #main,
  .pt_homepage.toggle-sidebar #footer {
    margin-left: 0 !important;
  }

  .pt_homepage.toggle-sidebar .sidebar {
    left: -18.75rem !important;
  }
}

@media (max-width: 1024px) {
  .pt_homepage.toggle-sidebar .sidebar {
    left: 0 !important;
  }

  .pt_homepage .sidebar {
    min-width: 40vw;
  }

  .pt_homepage #main {
    padding: 0.2rem !important;
    margin-left: 0;
  }

  .pt_homepage .main .container-fluid {
    padding: 0.5rem 0.2rem;
  }

  .pt_nav #sidebar {
    background: var(
      --background-color,
      linear-gradient(
        -45deg,
        rgba(100, 164, 216, 0.95),
        rgb(70, 130, 180, 0.95),
        rgb(27, 78, 121, 0.95)
      )
    ) !important;
  }

  .pt_caregiver_image {
    width: 4.5rem;
    height: 4.5rem;
  }
}
