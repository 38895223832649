/**
* Template Name: NiceAdmin - v2.4.1
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

:root {
  scroll-behavior: smooth;
}

a {
  color: var(--text-color, #4154f1);
  text-decoration: none;
}

a:hover {
  color: var(--text-color, #5db6ff);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}

/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/

#main {
  margin-top: 3.75rem;
  padding: 1.25rem 1.875rem;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  #main {
    padding: 1.25rem;
  }
}

/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/

.pagetitle {
  margin-bottom: 0.625rem;
}

.pagetitle h1 {
  font-size: 1.5rem;
  margin-bottom: 0;
  color: var(--text-color, #25649e);
  font-weight: 600;
  font-size: 2.1rem !important;
  filter: drop-shadow(-1px -1px 0.8px #25649e6b);
  font-family: "Nunito", sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 0.9375rem;
  bottom: 0.9375rem;
  z-index: 99999;
  background: #4154f1;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 1.5rem;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #6776f4;
  color: var(--text-color, #fff);
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/

/* Dropdown menus */

.dropdown-menu {
  border-radius: 0.25rem;
  padding: 0.625rem 0;
  -webkit-animation-name: dropdown-animate;
  animation-name: dropdown-animate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 0.3125rem 1.875rem 0 rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header,
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 0.9375rem;
  padding: 0.625rem 1.5625rem;
}

.dropdown-menu .dropdown-footer a {
  color: var(--text-color, #444444);
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover {
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 0.875rem;
  padding: 0.625rem 0.9375rem;
  transition: 0.3s;
}

.dropdown-menu .dropdown-item i {
  margin-right: 0.625rem;
  font-size: 1.125rem;
  line-height: 0;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) {
  .dropdown-menu-arrow::before {
    content: "";
    width: 0.8125rem;
    height: 0.8125rem;
    background: #fff;
    position: absolute;
    top: -0.4375rem;
    right: 1.25rem;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

/* Light Backgrounds */

.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

/* Card */

.card {
  margin-bottom: 1.875rem;
  border: none;
  border-radius: 0.3125rem;
  box-shadow: 0 0 1.875rem rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 0.9375rem;
}

.card-title {
  padding: 1.25rem 0 0.9375rem 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--text-color, #012970);
  font-family: "Poppins", sans-serif;
}

.card-title span {
  color: var(--text-color, #899bbd);
  font-size: 0.875rem;
  font-weight: 400;
}

.card-body {
  padding: 0 1.25rem 1.25rem 1.25rem;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

/* Alerts */

.alert-heading {
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
}

/* Close Button */

.btn-close {
  background-size: 25%;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
}

/* Accordion */

.accordion-item {
  border: 0.0625rem solid #ebeef4;
}

.accordion-button:focus {
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: var(--text-color, #012970);
  background-color: #f6f9ff;
}

.accordion-flush .accordion-button {
  padding: 0.9375rem 0;
  background: none;
  border: 0;
}

.accordion-flush .accordion-button:not(.collapsed) {
  box-shadow: none;
  color: var(--text-color, #4154f1);
}

.accordion-flush .accordion-body {
  padding: 0 0 0.9375rem 0;
  color: #3e4f6f;
  font-size: 0.9375rem;
}

/* Breadcrumbs */

.breadcrumb {
  font-size: 0.875rem;
  font-family: "Nunito", sans-serif;
  color: var(--text-color, #899bbd);
  font-weight: 600;
}

.breadcrumb a {
  color: var(--text-color, #899bbd);
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: var(--text-color, #51678f);
}

.breadcrumb .breadcrumb-item::before {
  color: var(--text-color, #899bbd);
}

.breadcrumb .active {
  color: var(--text-color, #51678f);
  font-weight: 600;
}

/* Bordered Tabs */

.nav-tabs-bordered {
  border-bottom: 0.125rem solid #ebeef4;
}

.nav-tabs-bordered .nav-link {
  margin-bottom: -0.125rem;
  border: none;
  color: var(--text-color, #2c384e);
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: var(--text-color, #4154f1);
}

.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: var(--text-color, #ffffff);
  border-bottom: 0.125rem solid #4154f1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.logo {
  line-height: 1;
}

@media (min-width: 1200px) {
  .logo {
    width: 17.5rem;
  }
}

.logo img {
  max-height: 1.625rem;
  margin-right: 0.375rem;
}

.logo span {
  font-size: 1.625rem;
  font-weight: 700;
  color: var(--text-color, #012970);
  font-family: "Nunito", sans-serif;
}
.header {
  transition: all 0.5s;
  z-index: 997;
  height: 3.75rem;
  box-shadow: 0rem 0.125rem 1.25rem rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding-left: 1.25rem;
}

.header .toggle-sidebar-btn {
  font-size: 2rem;
  padding-left: 0.625rem;
  cursor: pointer;
  color: var(--text-color, #012970);
}

.header .search-bar {
  min-width: 22.5rem;
  padding: 0 1.25rem;
}

@media (max-width: 1199px) {
  .header .search-bar {
    position: fixed;
    top: 3.125rem;
    left: 0;
    right: 0;
    padding: 1.25rem;
    box-shadow: 0rem 0rem 0.9375rem 0rem rgba(1, 41, 112, 0.1);
    background: white;
    z-index: 9999;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
  }
  .header .search-bar-show {
    top: 3.75rem;
    visibility: visible;
    opacity: 1;
  }
}

.header .search-form {
  width: 100%;
}

.header .search-form input {
  border: 0;
  font-size: 0.875rem;
  color: var(--text-color, #012970);
  border: 0.0625rem solid rgba(1, 41, 112, 0.2);
  padding: 0.4375rem 2.375rem 0.4375rem 0.5rem;
  border-radius: 0.1875rem;
  transition: 0.3s;
  width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
  outline: none;
  box-shadow: 0 0 0.625rem 0 rgba(1, 41, 112, 0.15);
  border: 0.0625rem solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
  border: 0;
  padding: 0;
  margin-left: -1.875rem;
  background: none;
}

.header .search-form button i {
  color: var(--text-color, #012970);
}

.header-nav ul {
  list-style: none;
}

.header-nav > ul {
  margin: 0;
  padding: 0;
}

.header-nav .nav-icon {
  font-size: 1.375rem;
  color: var(--text-color, #012970);
  margin-right: 1.5625rem;
  position: relative;
}

.header-nav .nav-profile {
  color: var(--text-color, #012970);
}

.header-nav .nav-profile img {
  max-height: 2.25rem;
}

.header-nav .nav-profile span {
  font-size: 0.875rem;
  font-weight: 600;
}

.header-nav .badge-number {
  position: absolute;
  inset: -0.125rem -0.3125rem auto auto;
  font-weight: normal;
  font-size: 0.75rem;
  padding: 0.1875rem 0.375rem;
}

.header-nav .notifications {
  inset: 0.5rem -0.9375rem auto auto !important;
}

.header-nav .notifications .notification-item {
  display: flex;
  align-items: center;
  padding: 0.9375rem 0.625rem;
  transition: 0.3s;
}

.header-nav .notifications .notification-item i {
  margin: 0 1.25rem 0 0.625rem;
  font-size: 1.5rem;
}

.header-nav .notifications .notification-item h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.3125rem;
}

.header-nav .notifications .notification-item p {
  font-size: 0.8125rem;
  margin-bottom: 0.1875rem;
  color: var(--text-color, #919191);
}

.header-nav .notifications .notification-item:hover {
  background-color: #f6f9ff;
}

.header-nav .messages {
  inset: 0.5rem -0.9375rem auto auto !important;
}

.header-nav .messages .message-item {
  padding: 0.9375rem 0.625rem;
  transition: 0.3s;
}

.header-nav .messages .message-item a {
  display: flex;
}

.header-nav .messages .message-item img {
  margin: 0 1.25rem 0 0.625rem;
  max-height: 2.5rem;
}

.header-nav .messages .message-item h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.3125rem;
  color: var(--text-color, #444444);
}

.header-nav .messages .message-item p {
  font-size: 0.8125rem;
  margin-bottom: 0.1875rem;
  color: var(--text-color, #919191);
}

.header-nav .messages .message-item:hover {
  background-color: #f6f9ff;
}

/* .======================== */
.header-nav .notifications {
  inset: 0.5rem -0.9375rem auto auto !important;
}

.header-nav .notifications .message-item {
  padding: 0.9375rem 0.625rem;
  transition: 0.3s;
}

.header-nav .notifications .message-item a {
  display: flex;
}

.header-nav .notifications .message-item img {
  margin: 0 1.25rem 0 0.625rem;
  max-height: 2.5rem;
}

.header-nav .notifications .message-item h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.3125rem;
  color: var(--text-color, #444444);
}

.header-nav .notifications .message-item p {
  font-size: 0.8125rem;
  margin-bottom: 0.1875rem;
  color: var(--text-color, #919191);
}

.header-nav .notifications .message-item:hover {
  background-color: #f6f9ff;
}
/* .======================== */

.header-nav .profile {
  min-width: 15rem;
  padding-bottom: 0;
  top: 0.5rem !important;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 1.125rem;
  margin-bottom: 0;
  font-weight: 600;
  color: var(--text-color, #444444);
}

.header-nav .profile .dropdown-header span {
  font-size: 0.875rem;
}

.header-nav .profile .dropdown-item {
  font-size: 0.875rem;
  padding: 0.625rem 0.9375rem;
  transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
  margin-right: 0.625rem;
  font-size: 1.125rem;
  line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
  background-color: #f6f9ff;
}

.sidebar {
  position: fixed;
  top: 3.75rem;
  left: 0;
  bottom: 0;
  z-index: 996;
  transition: all 0.3s;
  padding: 1.25rem;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0rem 0rem 1.25rem rgba(1, 42, 112, 0.438);
  background-color: #fff;
}

@media (max-width: 800px) {
  .sidebar {
    min-width: 40vw;
  }

  #main {
    padding: 0.2rem !important;
  }

  .main .container-fluid {
    padding: 0.2rem;
  }
}

.sidebar::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.5rem;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 800px) {
  #main,
  #footer {
    margin-left: 15rem;
  }
}

@media (max-width: 800px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 800px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }
  .toggle-sidebar .sidebar {
    left: -18.75rem;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 0.3125rem;
}

.sidebar-nav .nav-heading {
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: var(--text-color, #899bbd);
  font-weight: 600;
  margin: 0.625rem 0 0.3125rem 0.9375rem;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: 600;
  color: var(--text-color, #b0bcd1);
  transition: 0.3s;
  background: #f6f9ff;
  padding: 0.625rem 0.9375rem;
  border-radius: 0.25rem;
  z-index: 1500 !important;
}

.sidebar-nav .nav-link.active_group {
  color: var(--text-color, #ffffff);
  transform: translateX(-0.5rem);
}
.sidebar-nav .nav-link.active_group i {
  color: var(--text-color, #ffffff);
}

.sidebar-nav .nav-link i {
  font-size: 1rem;
  margin-right: 0.625rem;
  color: var(--text-color, #eff5ff);
}

.sidebar-nav .nav-link.collapsed {
  color: var(--text-color, #eff5ff);
  background: #fff;
}

.sidebar-nav .nav-link i {
  color: var(--text-color, #b0bcd1);
}

.sidebar-nav .nav-link:hover {
  color: var(--text-color, #ffffff);
  background: #f6f9ff;
}
.sidebar-nav .nav-link:focus {
  color: var(--text-color, #ffffff);
}

.sidebar-nav .nav-link:hover i {
  color: var(--text-color, #ffffff);
}

.sidebar-nav .nav-link .bi-chevron-right {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 0.3125rem 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-color, #b0bcd1d3);
  transition: 0.3;
  padding: 0.625rem 0 0.625rem 2.5rem;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 0.375rem;
  margin-right: 0.5rem;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: var(--text-color, #ffffff);
  transform: scale3d(1.1, 1.1, 1.1);
  background: #f6f9ff;
  text-shadow: 0 0 2rem #ffffff73;
}

.sidebar-nav .nav-content a:hover i,
.sidebar-nav .nav-content a.active i {
  color: #fff !important;
  text-shadow: 0 0 2rem #ffffff73;
}

.main .container-fluid {
  padding: 1rem;
}

.card {
  border: 0.0625rem solid rgba(1, 41, 112, 0.1);
  background: #fff;
  transition: 0.3s;
  border-radius: 0.25rem;
}

.card .card-header {
  background-color: #fff;
  padding: 0.9375rem 1.25rem;
  border: none;
  border-bottom: 0.0625rem solid rgba(1, 41, 112, 0.1);
  font-weight: 600;
  font-size: 0.9375rem;
}

.card .card-body {
  padding: 1.25rem;
}

.card .card-footer {
  background-color: #fff;
  border-top: 0.0625rem solid rgba(1, 41, 112, 0.1);
  padding: 0.9375rem 1.25rem;
}
.dashboard .filter .dropdown-item {
  padding: 0.5rem 0.9375rem;
}

/* Info Cards */

.dashboard .info-card {
  padding-bottom: 0.625rem;
}

.dashboard .info-card h6 {
  font-size: 1.75rem;
  color: var(--text-color, #012970);
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.dashboard .card-icon {
  font-size: 2rem;
  line-height: 0;
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.dashboard .sales-card .card-icon {
  color: var(--text-color, #4154f1);
  background: #f6f6fe;
}

.dashboard .revenue-card .card-icon {
  color: var(--text-color, #2eca6a);
  background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
  color: var(--text-color, #ff771d);
  background: #ffecdf;
}

/* Activity */

.dashboard .activity {
  font-size: 0.875rem;
}

.dashboard .activity .activity-item .activite-label {
  color: var(--text-color, #888);
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 4rem;
}

.dashboard .activity .activity-item .activite-label::before {
  content: "";
  position: absolute;
  right: -0.6875rem;
  width: 0.25rem;
  top: 0;
  bottom: 0;
  background-color: #eceefe;
}

.dashboard .activity .activity-item .activity-badge {
  margin-top: 0.1875rem;
  z-index: 1;
  font-size: 0.6875rem;
  line-height: 0;
  border-radius: 50%;
  flex-shrink: 0;
  border: 0.1875rem solid #fff;
  flex-grow: 0;
}

.dashboard .activity .activity-item .activity-content {
  padding-left: 0.625rem;
  padding-bottom: 1.25rem;
}

.dashboard .activity .activity-item:first-child .activite-label::before {
  top: 0.3125rem;
}

.dashboard .activity .activity-item:last-child .activity-content {
  padding-bottom: 0;
}

/* News & Updates */

.dashboard .news .post-item + .post-item {
  margin-top: 0.9375rem;
}

.dashboard .news img {
  width: 5rem;
  border-radius: 0.3125rem;
}

.dashboard .news h4 {
  font-size: 0.9375rem;
  margin-left: 5.9375rem;
  font-weight: bold;
  margin-bottom: 0.3125rem;
}

.dashboard .news h4 a {
  color: var(--text-color, #012970);
  transition: 0.3s;
}

.dashboard .news h4 a:hover {
  color: var(--text-color, #4154f1);
}

.dashboard .news p {
  font-size: 0.875rem;
  color: var(--text-color, #777777);
  margin-left: 5.9375rem;
}

.dashboard .recent-sales {
  font-size: 0.875rem;
}

.dashboard .recent-sales .table thead {
  background: #f6f6fe;
}

.dashboard .recent-sales .table thead th {
  border: 0;
}

.dashboard .recent-sales .dataTable-top {
  padding: 0 0 0.625rem 0;
}

.dashboard .recent-sales .dataTable-bottom {
  padding: 0.625rem 0 0 0;
}

.dashboard .top-selling {
  font-size: 0.875rem;
}

.dashboard .top-selling .table thead {
  background: #f6f6fe;
}

.dashboard .top-selling .table thead th {
  border: 0;
}

.dashboard .top-selling .table tbody td {
  vertical-align: middle;
}

.dashboard .top-selling img {
  border-radius: 0.3125rem;
  max-width: 3.75rem;
}

.iconslist {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(11.25rem, 1fr));
  gap: 1.25rem;
  padding-top: 0.9375rem;
}

.iconslist .icon {
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center;
  color: var(--text-color, #012970);
  padding: 0.9375rem 0;
}

.iconslist i {
  margin: 0.25rem;
  font-size: 2.5rem;
}

.iconslist .label {
  font-family: var(--bs-font-monospace);
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 0.25rem;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-color, #666);
}

.profile .profile-card img {
  max-width: 7.5rem;
}

.profile .profile-card h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-color, #2c384e);
  margin: 0.625rem 0 0 0;
}

.profile .profile-card h3 {
  font-size: 1.125rem;
}

.profile .profile-card .social-links a {
  font-size: 1.25rem;
  display: inline-block;
  color: var(--text-color, #01297080);
  line-height: 0;
  margin-right: 0.625rem;
  transition: 0.3s;
}

.profile .profile-card .social-links a:hover {
  color: var(--text-color, #012970);
}

.profile .profile-overview .row {
  margin-bottom: 1.25rem;
  font-size: 0.9375rem;
}

.profile .profile-overview .card-title {
  color: var(--text-color, #012970);
}

.profile .profile-overview .label {
  font-weight: 600;
  color: var(--text-color, #01297099);
}

.profile .profile-edit label {
  font-weight: 600;
  color: var(--text-color, #01297099);
}

.profile .profile-edit img {
  max-width: 7.5rem;
}

.faq .basic h6 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color, #4154f1);
}

.faq .basic p {
  color: var(--text-color, #6980aa);
}

.contact .info-box {
  padding: 1.75rem 1.875rem;
}

.contact .info-box i {
  font-size: 2.375rem;
  line-height: 0;
  color: var(--text-color, #4154f1);
}

.contact .info-box h3 {
  font-size: 1.25rem;
  color: var(--text-color, #012970);
  font-weight: 700;
  margin: 1.25rem 0 0.625rem 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 1.5rem;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.contact .php-email-form .error-message {
  display: none;
  color: var(--text-color, #fff);
  background: #ed3c0d;
  text-align: left;
  padding: 0.9375rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: var(--text-color, #fff);
  background: #18d26e;
  text-align: center;
  padding: 0.9375rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 0.9375rem;
  margin-bottom: 1.5rem;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.625rem -0.375rem 0;
  border: 0.1875rem solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 0.875rem;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #4154f1;
}

.contact .php-email-form input {
  padding: 0.625rem 0.9375rem;
}

.contact .php-email-form textarea {
  padding: 0.75rem 0.9375rem;
}

.contact .php-email-form button[type="submit"] {
  background: #4154f1;
  border: 0;
  padding: 0.625rem 1.875rem;
  color: var(--text-color, #fff);
  transition: 0.4s;
  border-radius: 0.25rem;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #5969f3;
}

.error-404 {
  padding: 1.875rem;
}

.error-404 h1 {
  font-size: 11.25rem;
  font-weight: 700;
  color: var(--text-color, #4154f1);
  margin-bottom: 0;
  line-height: 9.375rem;
}

.error-404 h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-color, #012970);
  margin-bottom: 1.875rem;
}

.error-404 .btn {
  background: #51678f;
  color: var(--text-color, #fff);
  padding: 0.5rem 1.875rem;
}

.error-404 .btn:hover {
  background: #3e4f6f;
}

@media (min-width: 992px) {
  .error-404 img {
    max-width: 50%;
  }
}

.footer {
  padding: 1.25rem 0;
  font-size: 0.875rem;
  transition: all 0.3s;
  border-top: 0.0625rem solid #cddfff;
}

.footer .copyright {
  text-align: center;
  color: var(--text-color, #012970);
}

.footer .credits {
  padding-top: 0.3125rem;
  text-align: center;
  font-size: 0.8125rem;
  color: var(--text-color, #012970);
}

.nav-animation-enter {
  opacity: 0;
  transform: translateY(-0.625rem);
}

.nav-animation-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

/* override MUI z-index overlay issue */
.MuiDataGrid-overlayWrapper {
  z-index: unset !important;
}
